import { Box, Container } from "@mui/material";
import SpoTrueLoginComponent from "../components/login/SpoTrueLoginComponent";
import { useAuthContext } from "../providers/AuthProvider";
import LoggedInUserInfo from "./LoggedInUserInfo";

const SpoTrueLoginPage = () => {
  const { isAuthenticated } = useAuthContext();

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      {isAuthenticated ? (
        <LoggedInUserInfo redirect={"/login"} />
      ) : (
        <Box
          sx={{
            maxWidth: "400px",
            border: "1px solid #ddd",
            borderRadius: "10px",
            padding: "25px",
            marginTop: "25px",
          }}
        >
          <SpoTrueLoginComponent />
        </Box>
      )}
    </Container>
  );
};

export default SpoTrueLoginPage;
