import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl } from "./baseUrls";

export default function getSignedUrl(
  authHeader: string,
  gogigReqId:string,
  url: string
) {
  const target = process.env.REACT_APP_TARGET;
  const endPointURl: string = `${SpotrueApiStorageBaseUrl}/getFileAccessUrl?tenant=${target}`;
  return fetchWrapper(endPointURl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
    body: {
      url: url,
    },
  });
}
