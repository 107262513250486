import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Button,
  Box,
  Avatar,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { camelCase } from "../../utils/utils";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import { ProfileData } from "../profile/ProfileValues";
import { useAuthContext } from "../../providers/AuthProvider";

interface NavigationProps {
  closeMobileMenu: () => void;
  profileData?: ProfileData | null;
  onClick: () => void;
}

const AuditProfileMenu: React.FC<NavigationProps> = ({
  closeMobileMenu,
  profileData,
  onClick,
}) => {
  const history = useNavigate();
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));
  const { isAuthenticated, logout } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNavigation = (destination: string) => {
    const currentPath = window.location.pathname;

    if (currentPath === destination) {
      window.location.reload();
    } else {
      history(destination);
    }

    closeMobileMenu();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function stringToColor(string: string | null) {
    if (string == null) return "#000";
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(firstName: string, lastName: string) {
    const avatarText = (
      (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "")
    ).toUpperCase();

    return {
      sx: {
        bgcolor: stringToColor(firstName),
      },
      children: avatarText || "",
    };
  }
  const handleProfileClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNavigation("/profile");
    window.scrollTo(0, 0);
    onClick();
    handleClose();
  };

  return (
    <>
      <>
        {" "}
        {!matchMobileView && (
          <Box sx={{ display: "flex" }}>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              slotProps={{
                paper: {
                  sx: {
                    minWidth: "150px",
                    borderRadius: "7px",
                    boxShadow: "0px 0px 5px 0px #99dfff",
                  },
                },
              }}
            >
              {isAuthenticated && (
                <MenuItem
                  onClick={(e: any) => {
                    handleProfileClick(e);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#F0F7FF",
                      borderRadius: "10px",
                    },
                  }}
                >
                  My Profile
                  <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                </MenuItem>
              )}
              {isAuthenticated && (
                <MenuItem
                onClick={() => logout({ returnTo: "/" })}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#F0F7FF",
                      borderRadius: "10px",
                    },
                  }}
                >
                  Logout
                  <LogoutIcon sx={{ fontSize: "20px" }} />
                </MenuItem>
              )}
            </Menu>
            {isAuthenticated && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    minWidth: "max-content",
                  }}
                >
                  {!matchMobileView && (
                    <Button
                      onClick={handleClick}
                      sx={{
                        textTransform: "none",
                        color: "primary.dark",
                        fontSize: "16px",
                      }}
                    >
                      {profileData?.profilePicture === "" ? (
                        <PersonIcon
                          sx={{ color: "primary.dark", marginRight: "5px" }}
                        />
                      ) : (
                        profileData && (
                          <Avatar
                            {...stringAvatar(
                              profileData.firstName,
                              profileData.lastName
                            )}
                            src={profileData?.profilePicture}
                            sx={{
                              width: 36,
                              height: 36,
                              mr: 1,
                              backgroundColor: stringToColor(
                                profileData.firstName +
                                  " " +
                                  profileData.lastName
                              ),
                            }}
                          />
                        )
                      )}
                      {profileData
                        ? `${camelCase(profileData.firstName)} ${
                            profileData.lastName
                              ? camelCase(profileData.lastName)
                              : ""
                          }`
                        : "Guest User"}
                      <ExpandMoreIcon sx={{ marginLeft: "5px" }} />
                    </Button>
                  )}
                </Box>
              </>
            )}
            
          </Box>
        )}
      </>
      <>
        {matchMobileView && (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              padding: "10px",
              marginLeft: "4px",
            }}
          >
            {profileData?.profilePicture === "" ? (
              <PersonIcon />
            ) : (
              profileData && (
                <Avatar
                  {...stringAvatar(profileData.firstName, profileData.lastName)}
                  src={profileData?.profilePicture}
                  sx={{
                    width: 64,
                    height: 64,
                    mr: 1,
                    backgroundColor: stringToColor(
                      profileData.firstName + " " + profileData.lastName
                    ),
                  }}
                />
              )
            )}
            {profileData?.firstName === "" ? (
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Guest User
              </Typography>
            ) : (
              <>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  {profileData
                    ? `${camelCase(profileData.firstName)} ${
                        profileData.lastName
                          ? camelCase(profileData.lastName)
                          : ""
                      }`
                    : "Guest User"}
                </Typography>
                <Button
                  variant="text"
                  style={{
                    textTransform: "none",
                    color: "gray",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "start",
                    marginLeft: "-6px",
                    marginTop: "-4px",
                  }}
                  onClick={(e: any) => {
                    handleProfileClick(e);
                  }}
                >
                  View Profile
                </Button>
              </>
            )}
          </Typography>
        )}
      </>
    </>
  );
};

export default AuditProfileMenu;
