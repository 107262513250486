import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";

// Fix for Tooltip issue, make sure you're passing the correct component and props

const SpoTrueLogo = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const onClick = () => {
    navigate("/");
  };

  return (
    <Box onClick={onClick}sx={{mt:1}}>
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/spotrue.png`}
        alt=" SpoTrue "
        style={{ width:isMobile? 90:200, justifyContent:"center",}}
      />
    </Box>
  );
};

export default SpoTrueLogo;
