import {
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import Dashboard from "./Dashboard";
import { useAuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../types/userRoles";

const VendorDashboard = () => {
  const { userDetails } = useAuthContext();
  const navigate = useNavigate();
  const handleOpenAuditDialog = () => {
    navigate("/upload-createRequest");
  };
  const hasAccess =
    userDetails?.role === userRoles.admin ||
    userDetails?.role === userRoles.operations ||
    userDetails?.role === userRoles.processor ||
    userDetails?.role === userRoles.external;
  return (
    <Container
      sx={{
        marginTop: 3,
      }}
    >
      <Box>
        {userDetails && (
          <Typography
            variant="h4"
            component="h6"
            textAlign="center"
            sx={{
              fontSize: { xs: "1.5rem", sm: "2.125rem" }, // Smaller font size on mobile
            }}
          >
            Hello! {userDetails.firstName} {userDetails.lastName}
          </Typography>
        )}
        <Typography
          variant="h5"
          textAlign="center"
          gutterBottom
          sx={{
            fontSize: { xs: "1.25rem", sm: "1.5rem" }, // Adjust font size for mobile
          }}
        >
          We are glad to have you here
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-end" }, // Center button on mobile
          mt: { xs: 2, sm: 0 }, // Add margin on top for spacing on mobile
        }}
      >
        {hasAccess && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAuditDialog}
            sx={{
              width: { xs: "100%", sm: "auto" }, // Full-width button on mobile
              fontSize: { xs: "0.875rem", sm: "1rem" }, // Adjust font size
            }}
          >
            Add New Campaign
          </Button>
        )}
      </Box>

      <Dashboard />
    </Container>
  );
};

export default VendorDashboard;
