import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Card,
  Divider,
  Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import { getAuditRequestById } from "../../clients/getAuditRequestById";
import { AuditRequest } from "./vehicleAuditComponents/types";
import getSignedUrl from "../../clients/getSignedUrl";
import RequestStatusProgress from "./RequestStatusProgress";
import AuditRequestDetailsCard from "./AuditRequestDetailsCard";

const AuditRequestPage = () => {
  const [fileData, setFileData] = useState<AuditRequest>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileDetails, setFileDetails] = useState<
    { name: string; url: string }[]
  >([]);
  const { gogigReqId } = useParams<{ gogigReqId: string }>();
  const { authHeader } = useAuthContext();

  const handlePptUrl = async (pptUrl: string): Promise<string | undefined> => {
    if (!authHeader || !gogigReqId) return;

    try {
      const res = await getSignedUrl(authHeader, gogigReqId, pptUrl);
      const signedUrl = res.data as { url: string };
      return signedUrl.url;
    } catch (error) {
      console.error("Failed to fetch signed PPT URL", error);
    }
  };

  const fetchFileDetails = async (urls: string) => {
    const urlArray = urls.split(",").map((url) => url.trim());
    const fileData = await Promise.all(
      urlArray.map(async (url) => {
        const signedUrl = await handlePptUrl(url);
        const fileName = url.split("/").pop() || "Unknown File";
        return signedUrl ? { name: fileName, url: signedUrl } : null;
      })
    );
    setFileDetails(fileData.filter(Boolean) as { name: string; url: string }[]);
  };

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gogigReqId) {
          setIsLoading(true);
          const response = await getAuditRequestById(authHeader, gogigReqId);
          const data = response as AuditRequest;
          setFileData(data);

          if (data.clientUrls) {
            await fetchFileDetails(data.clientUrls);
          }
        }
      } catch (error) {
        console.error("Error fetching audit request:", error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);

  return (

    <Container sx={{padding:2, mt:4}}   >
      {isLoading ? (
        <Card
          variant="outlined"
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            boxShadow: 3,
            width: "100%", // Full width for loading
          }}
        >
          <CircularProgress />
        </Card>
      ) : (
        <Box display="flex" flexDirection="column" gap={4} width="100%">
             {/* Audit Request Details Card */}
             <AuditRequestDetailsCard fileData={fileData} fileDetails={fileDetails}/>
          <Typography sx={{mb:0}} variant="h4">Progress and Step Info Card</Typography>
          <Card
            variant="outlined"
            sx={{
              
             
              borderRadius: "8px",
              boxShadow: 4,
              backgroundColor: "#f9f9f9",
            }}
          >
            {fileData && (
              <Box display={"flex"} sx={{flexDirection:{md:"row",xs:"column"}}}>
                {/* Request Progress */}
                <RequestStatusProgress
                  requestStatus={fileData.requestStatus}
                  dueDate={fileData.dueDate}
                  allowRequestEdit={fileData.allowRequestEdit}
                />
                <Divider sx={{ my: 2 }} />
                {/* Current and Next Steps */}
                
              </Box>
            )}
          </Card>
         
    
       
        </Box>
      )}
    </Container>
    
  );
};

export default AuditRequestPage;
