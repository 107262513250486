import React from "react";
import {
  Typography,
  Box,
  TextField,
  CircularProgress,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Tooltip,
  MenuItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { AuditRequest } from "../types";

const vehicleClassDropDown: { [key: string]: string } = {
  "Bus Branding": "Bus Branding",
  "Auto Rickshaw Branding": "Auto Rickshaw Branding",
  "Cab Branding": "Cab Branding",
};

interface AuditRequestFormProps {
  isRequestTitle: boolean;
  loading: boolean;
  selectedFiles: File[];

  isFileUpload: boolean | null;
  uploadProgress: Record<number, number>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  comment: string;
  requestDetails?: AuditRequest;
  errorMessage: string | null;
  vehicleClass: string;
  location: string;
  campaignEndDate: string;
  campaignStartDate: string;
  campaignName: string;
  handleUpload: () => void;
  onRemoveFile: (index: number) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // deletingIndex: number | null;
  // isDeleted: boolean;
  // uploadedUrls?:(string|null)[];
}

export default function AuditRequestForm({
  loading,
  isFileUpload,
  uploadProgress,
  selectedFiles,
  comment,
  requestDetails,
  errorMessage,
  vehicleClass,
  location,
  campaignEndDate,
  campaignStartDate,
  campaignName,
  setComment,
  handleUpload,
  onRemoveFile,
  handleInputChange,
  handleFileSelection,
  // deletingIndex,
}: // isDeleted,
// uploadedUrls,
AuditRequestFormProps) {
  const handleSubmit = () => {
    handleUpload();
  };
  const fieldInfo = {
    campaignName: "Provide a name for your campaign.",
    location: "Specify the location or region where the campaign took place.",
    campaignStartDate: "Choose the starting date of your campaign.",
    campaignEndDate: "Choose the ending date of your campaign.",
    vehicleClass: "Select the type of vehicles involved in the campaign.",
  };

  return (
    <Box
      sx={{
        maxWidth: 675,
        margin: "0 auto",
        padding: { xs: 2, sm: 3, md: 4 },
        border: "1px solid #ddd",
        borderRadius: 6,
        boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Typography
        variant="h5"
        sx={{ textAlign: "center", mb: 3, fontWeight: 600 }}
      >
        {requestDetails?.allowRequestEdit
          ? "Update You Audit Request"
          : "Create Your Audit Request"}
      </Typography>

      {/* Step 1: Campaign Details */}
      <Typography
        variant="body1"
        sx={{
          mb: 2,
          fontWeight: 500,
        }}
      >
        <strong>Step 1:</strong>
        {requestDetails?.allowRequestEdit ? "Update" : "Add"} campaign details,
        including campaign name, location, duration, and vehicle class.
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <TextField
          label="Campaign Name"
          variant="outlined"
          fullWidth
          value={
            
               campaignName
          }
          name="campaignName"
          
          InputLabelProps={{
            sx: {
              "& .MuiInputLabel-asterisk": {
                color: "red", // Set the asterisk color to red
              },
            },
          }}
          error={!!errorMessage}
          helperText={errorMessage}
          onChange={handleInputChange}
        />
        <Tooltip title={fieldInfo.campaignName} placement="right">
          <InfoIcon sx={{ ml: 1, color: "info.main", cursor: "pointer" }} />
        </Tooltip>
      </Box>

      {/* Location Field */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <TextField
          label="Location"
          name="location"
          variant="outlined"
          fullWidth
          value={
           
               location
          }
          onChange={handleInputChange}
        />
        <Tooltip title={fieldInfo.location} placement="right">
          <InfoIcon sx={{ ml: 1, color: "info.main", cursor: "pointer" }} />
        </Tooltip>
      </Box>

      {/* Campaign Dates */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          mb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
          <TextField
            label="Start Date"
            type="date"
            name="campaignStartDate"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={
             
                 campaignStartDate
            }
            onChange={handleInputChange}
            inputProps={{
              max: new Date().toISOString().split("T")[0], // Restricts to today
            }}
          />
          <Tooltip title={fieldInfo.campaignStartDate} placement="right">
            <InfoIcon sx={{ ml: 1, color: "info.main", cursor: "pointer" }} />
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
          <TextField
            label="End Date"
            type="date"
            name="campaignEndDate"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={
              campaignEndDate
            }
            onChange={handleInputChange}
            inputProps={{
              min: campaignStartDate || undefined,
              max: new Date().toISOString().split("T")[0], // Restricts to today
            }}
          />
          <Tooltip title={fieldInfo.campaignEndDate} placement="right">
            <InfoIcon sx={{ ml: 1, color: "info.main", cursor: "pointer" }} />
          </Tooltip>
        </Box>
      </Box>

      {/* Vehicle Class Dropdown */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
        <TextField
          label="Select Campaign Type"
          name="vehicleClass"
          select
          fullWidth
          value={
            vehicleClass
          }
          onChange={handleInputChange}
          variant="outlined"
        >
          {Object.entries(vehicleClassDropDown).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <Tooltip title={fieldInfo.vehicleClass} placement="right">
          <InfoIcon sx={{ ml: 1, color: "info.main", cursor: "pointer" }} />
        </Tooltip>
      </Box>

      {/* Step 2: File Upload */}
      <Typography
        variant="body1"
        sx={{
          mb: 2,
          fontWeight: 500,
        }}
      >
        <strong>Step 2:</strong>{" "}
        {requestDetails?.allowRequestEdit ? "Reupload" : "Upload"} your files
        for audit. Supported formats: PDF, PPTX, ZIP, XLSX. You can upload up to{" "}
        <strong>4 files.</strong>
      </Typography>

      <Box
        sx={{
          padding: 2,
          backgroundColor: "#f0f4ff",
          border: "2px dashed #1976d2",
          borderRadius: 3,
          textAlign: "center",
          cursor: "pointer",
          "&:hover": { backgroundColor: "#e3f2fd" },
        }}
        onClick={() => document.getElementById("file-input")?.click()}
      >
        <CloudUploadIcon color="primary" sx={{ fontSize: 50 }} />
        <Typography variant="body1" sx={{ mt: 1 }}>
          Click to browse files
        </Typography>
        <input
          type="file"
          hidden
          id="file-input"
          multiple
          onChange={handleFileSelection}
          accept=".pdf, .ppt, .pptx, .xls, .xlsx, .zip"
        />
      </Box>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mt: 2, fontStyle: "italic", textAlign: "center" }}
      >
        Providing more detailed and accurate data will result in better and more
        tailored audit reports.
      </Typography>

      {selectedFiles.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Selected Files:
          </Typography>
              <List sx={{ maxHeight: 250, overflowY: "auto" }}>
                {selectedFiles.map((file, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: 2,
                      mb: 2,
                      boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
                    }}
                    secondaryAction={
                      <Tooltip title="Remove File">
                        <IconButton
                          edge="end"
                          onClick={() => onRemoveFile(index)}
                          disabled={!!loading || !!isFileUpload}
                        >
                          <DeleteIcon color={loading ? "disabled"  :!!isFileUpload?"disabled": "error"} />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          sx={{
                            width: { xs: "200px", sm: "300px" }, // Set fixed width for different screen sizes
                            wordWrap: "break-word", // Ensures long words wrap
                            whiteSpace: "normal", // Allows wrapping
                            overflow: "hidden", // Prevents overflow
                          }}
                        >
                          {file.name} - {(file.size / 1000000).toFixed(2)} MB
                        </Typography>
                      }
                      secondary={
                        uploadProgress[index] < 100 ? (
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress
                              variant="determinate"
                              value={uploadProgress[index]}
                              sx={{ height: 6, borderRadius: 3 }}
                            />
                            <Typography variant="caption">
                              {uploadProgress[index]}% completed
                            </Typography>
                          </Box>
                        ) : (
                          <Typography variant="caption" color="success.main">
                            Upload complete
                          </Typography>
                        )
                      }
                    />
                  </ListItem>
                ))}
              </List>
           
          
        </Box>
      )}

      {/* Step 3: Comments */}
      {selectedFiles.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" sx={{ mb: 2, fontWeight: 500 }}>
            <strong>Step 3:</strong> Add comments or instructions for the audit
            team.
          </Typography>
          <TextField
            label="Comments about the files"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="E.g., password for protected files, specific instructions"
            disabled={loading || isFileUpload === true}
          />
        </Box>
      )}

      {/* Submit Button */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            isFileUpload === true || loading || selectedFiles.length === 0
          }
          fullWidth
          size="large"
        >
          {loading ? (
            <>
              {requestDetails?.allowRequestEdit?"Updating Request...":"Creating Request..."}
              <CircularProgress size={24} sx={{ ml: 2 }} />
            </>
          ) : (
            requestDetails?.allowRequestEdit ? "Update Audit Request...":"Create Audit Request..."
          )}
        </Button>
      </Box>
    </Box>
  );
}
