import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export interface FileUrls {
  clientUrls: string;
  clientComment: string;
  campaignName: string;
  location: string;
  campaignStartDate: string;
  campaignEndDate: string;
  vehicleClass: string;
}

export async function updateUserAuditRequest(
  authHeader: string,
  gogigReqId:string,
  data: FileUrls,
) {
  const payload = {
    clientUrls: data.clientUrls,
    clientComment: data.clientComment,
    campaignName: data.campaignName,
    campaignLocation: data.location,
    campaignStartDate: data.campaignStartDate,
    campaignEndDate: data.campaignEndDate,
    campaignVehicleClass: data.vehicleClass,
  };

  const url: string = `${SpotrueApiBaseUrl}/saveUserAuditRequest?gogigReqId=${gogigReqId}`; // Update endpoint based on cURL
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
    body: payload, // Ensure the payload is properly serialized as JSON
  });
}
