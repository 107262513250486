import React, { useState } from "react";
import {
  Box,
  Card,
  Avatar,
  Typography,
  Button,
  Chip,
  TablePagination,
  CircularProgress,
  IconButton,
  Snackbar,
  Grid,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { FileData } from "./Dashboard";
import { camelCaseToTitleCase } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

interface RequestDetailsCardProps {
  filesData: FileData[];
  handleCreateRequest: () => void;
  isLoading: boolean;
}

const RequestDetailsCard: React.FC<RequestDetailsCardProps> = ({
  filesData,
  handleCreateRequest,
  isLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isMobileView = useMediaQuery("(max-width:599px)");
const navigate = useNavigate()

  const sortedFilesData = [...filesData].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const paginatedFilesData = sortedFilesData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
const handleClick =(gogigRequestId:string)=>{
  navigate(`/audit-process/${gogigRequestId}`)
}
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const getStatusColor = (status?: string) => {
    switch (status) {
      case "completed":
      case "requestApproved":
        return "#d4edda"; // Green for positive statuses
      case "requestCreated":
        return "#d1ecf1";
      case "processing":
      case "requestUnderReview":
      case "resultsUnderReview":
        return "#fff7d8"; // Yellow for ongoing statuses
      case "rejected":
        return "#f8d7da"; // Red for rejected statuses
      default:
        return "#fefefe"; // Default to warning for unknown statuses
    }
  };
  const getStatusBorderColor = (status?: string) => {
    switch (status) {
      case "completed":
      case "requestApproved":
        return "#3b7c4d"; // Green for positive statuses
      case "requestCreated":
        return "#31708e";
      case "processing":
      case "requestUnderReview":
      case "resultsUnderReview":
        return "#ffd114"; // Yellow for ongoing statuses
      case "rejected":
        return "#d93036"; // Red for rejected statuses
      default:
        return "#808080"; // Default to warning for unknown statuses
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mt: isMobileView ? 2 : 0 }} gutterBottom>
        Uploaded Campaigns
      </Typography>

      {/* Display loading state with Card */}
      {isLoading ? (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            boxShadow: 3,
          }}
        >
          <CircularProgress />
        </Card>
      ) : filesData?.length ? (
        paginatedFilesData.map((file, index) => (
          <Card
          key={index}
          variant="outlined"
          elevation={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 2,
            padding: 2,
            backgroundColor: "white",
            boxShadow: 1,
          }}
          onClick={()=>handleClick(file.gogigRequestId)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1" fontWeight="bold">
                  Campaign Name: {file.campaignName ||  "Untitled Campaign"}
                </Typography>
                <Box display="flex" alignItems="center" mt={1}>
                  <Typography variant="body2" fontWeight="bold" mr={1}>
                    Request ID:
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ wordWrap: "break-word", // Ensures long words wrap
                    whiteSpace: "normal", width: { md: "180px", sm: "300px" }, }} >
                    {file.gogigRequestId}
                  </Typography>
                  <Tooltip title="Copy Request ID" arrow>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent card click
                        handleCopyToClipboard(file.gogigRequestId);
                      }}
                     
                    >
                      <ContentCopyRoundedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="body2" fontWeight="bold" mr={1}>
                    Uploaded At:
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {file.uploadDate} at {file.uploadTime}
                </Typography>
                </Box>
                
              </Box>
            </Grid>
            
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                display={"flex"}
                alignItems="center"
              >
                <Typography variant="body2" color="text.secondary" mr={1}>
                  Request Status:
                </Typography>
                <Chip
                  label={camelCaseToTitleCase(file.requestStatus)}
                  sx={{
                    color: getStatusBorderColor(file.requestStatus),
                    border: "1px solid #ffd114",
                    backgroundColor: getStatusColor(file.requestStatus),
                  }}
                  variant="outlined"
                />
              </Grid>

            <Grid item xs={12} sm={6} md={4} display="flex" alignItems="center" >
              {file.resultUrl ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<DownloadIcon />}
                  href={file.resultUrl}
                  download
                  target="_blank"
                >
                  Download
                </Button>
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                >
                  Auditing in progress. Audit Report will be available for Download, once
                  process is completed.
                </Typography>
              )}
            </Grid>
          </Grid>
        
          </Card>
        ))
      ) : (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 3,
            boxShadow: 3,
          }}
        >
          <Avatar
            sx={{
              bgcolor: "primary.light",
              width: 60,
              height: 60,
              margin: "0 auto",
              color: "primary.main",
            }}
          >
            <InsertDriveFileIcon sx={{ fontSize: 30 }} />
          </Avatar>
          <Typography variant="h6" color="text.secondary" mt={2}>
            No files found
          </Typography>
          <Typography variant="body2" color="text.secondary" mb={3}>
            You haven't uploaded any files yet. Create a request to get started.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCreateRequest}
          >
            Create New Campaign
          </Button>
        </Card>
      )}

      {/* Pagination */}
      {filesData.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={sortedFilesData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {/* Snackbar for confirmation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="ID copied to clipboard!"
      />
    </Box>
  );
};

export default RequestDetailsCard;
