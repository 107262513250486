import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl,clientId,SpotrueClientId } from "./baseUrls";


export interface Body {
  fileName: string;
  fileType: string;
}

export default function createFileUpload(authHeader:string, fileUrls: Body) {
  const target = process.env.REACT_APP_TARGET;
  const body: Body = {
    fileName: fileUrls.fileName,
    fileType: fileUrls.fileType,
  };

  return fetchWrapper(`${SpotrueApiStorageBaseUrl}/createFileUpload?clientId=${target==="spotrue"?SpotrueClientId:clientId}`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader,
    },
    body,
  });
}
