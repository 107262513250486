import React, { useEffect, useState } from "react";
import {
  Box,
} from "@mui/material";
import getAuditFunction from "../../clients/getAuditRequest";
import { useAuthContext } from "../../providers/AuthProvider";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import RequestDetailsCard from "./RequestDetailsCard";

export interface FileData {
  name: string;
  uploadDate: string;
  uploadTime: string;
  resultUrl?: string;
  pptUrl: string;
  sheetUrl: string;
  processorName: string;
  requesterName: string;
  requestTitle: string;
  requestStatus: string;
  gogigRequestId: string;
  createdAt: string;
  campaignName:string;
}

const Dashboard: React.FC = () => {
  const { authHeader } = useAuthContext();
  const [fileData, setFileData] = useState<FileData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (authHeader) {
          setIsLoading(true);
          const response: any = await getAuditFunction(authHeader);
          const data = response.data.map((file: any) => {
            return {
              name: file.gogigRequestId, // Or another appropriate field
              uploadDate: dateFormat(file.createdAt, "dd-mm-yyyy"),
              uploadTime: dateFormat(file.createdAt, "hh:MM TT"),
              resultUrl: file.resultUrl,
              pptUrl: file.requestedPptUrl,
              sheetUrl: file.requestedSheetUrl,
              processorName: file.processorName,
              requesterName: file.requesterName,
              requestTitle: file.requestTitle,
              gogigRequestId: file.gogigRequestId,
              requestStatus: file.requestStatus,
              createdAt: dateFormat(file.createdAt),
              campaignName:file.campaignName,
            };
          });
          setFileData(data);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        console.error("Error during getting Audit Function", e);
      }
    })();
  }, [authHeader]);
  const handleCreateRequest = () => {
    navigate("/upload-createRequest");
  };

  return (
    <Box>
      <RequestDetailsCard
        filesData={fileData}
        isLoading={isLoading}
        handleCreateRequest={handleCreateRequest}
      />
    </Box>
  );
};

export default Dashboard;
