import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl,clientId,SpotrueClientId } from "./baseUrls";

export default function generatePresignedUrls(
  fileId: string,
  id: string,
  parts: string
) {
  const target = process.env.REACT_APP_TARGET;
  return fetchWrapper(`${SpotrueApiStorageBaseUrl}/generatePresignedUrls?clientId=${target==="spotrue"?SpotrueClientId:clientId}`, {
    method: "POST",
    body: {
      fileId: fileId,
      uploadId: id,
      parts: parts,
    },
  });
}
