import {
    TextField,
    Typography,
    Button,
    IconButton,
    InputAdornment,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
  } from "@mui/material";
  import "./LoginPageCSS.css";
  import initialLoginValues, { LoginValues } from "./LoginValues";
  import { useState } from "react";
  import { VisibilityOff, Visibility } from "@mui/icons-material";
  import {useNavigate } from "react-router-dom";
  import ErrorIcon from "@mui/icons-material/Error";
  import LockIcon from "@mui/icons-material/Lock";
  import PersonIcon from "@mui/icons-material/Person";
  import { useAuthContext } from "../../providers/AuthProvider";
  
  const SpoTrueLoginComponent = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [values, setValues] = useState<LoginValues>(initialLoginValues);
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { authHeader,loginWithSpoTrue } = useAuthContext();
  
    const [errors, setErrors] = useState<any>({});
  
    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setErrorMessage("");
      setErrors({});
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };
  
    const redirectToSignUpPage = () => {
      history("/sign-up");
    };
    const handleDialogClose = () => {
      setDialogOpen(false); // Close the dialog
    };
    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      await handleSubmit();
    };
    const redirectToEmailVerifyPage = () => {
      history("/go-to-email-inbox", {
        state: { emailId: values.emailId },
      });
    };
    const handleSubmit = async () => {
      let newErrors = { ...errors };
      if (!values.emailId) {
        newErrors = { ...newErrors, emailId: "EmailId or Username is required" };
      }
      if (!values.password) {
        newErrors = { ...newErrors, password: "Password is required" };
      }
      setErrors(newErrors);
  
      if (!newErrors.checkbox && !newErrors.emailId && !newErrors.password) {
        setLoading(true);
        try {
          if (!authHeader) return;
            await loginWithSpoTrue(values.emailId, values.password, authHeader);
          setLoading(false);
          history("/")
        } catch (error: any) {
          setLoading(false);
          if (error.status === 400 && error.errorCause === "emailNotRegistered") {
            setErrorMessage("Username does not exist in our database");
            setErrorMessage("Username does not exist in our database");
          }
          if (error.status === 401) {
            setErrorMessage("Username or password is incorrect");
          }
          if (
            error.status === 400 &&
            error.errorCause === "emailVerificationPending"
          ) {
            setDialogMessage(
              "Email verification is pending. Please verify email."
            );
            setDialogOpen(true);
          }
        }
      }
    };
  
    const goToForgotPassword = () => {
      history("/forgot-password");
    };
    return (
      <form onSubmit={onFormSubmit}>
        {errorMessage && (
          <Typography
            sx={{ display: "flex", justifyContent: "center", color: "red" }}
          >
            {errorMessage}
          </Typography>
        )}
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
            fontWeight: "600",
            fontFamily: "Inter,sans-serif",
          }}
          gutterBottom
        >
          Log in to SpoTrue
        </Typography>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          {/* REVIST - Removed due to title mismatch for client*/}
          {/* Your Gateway to Flexible Work and Boundless Opportunities */}
        </Typography>
        <TextField
          fullWidth
          name="emailId"
          size="small"
          value={values.emailId}
          onChange={handleChange}
          autoComplete="email"
          placeholder="EmailId or username"
          sx={{
            marginTop: "15px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
            className: "textField-input",
          }}
          error={errors.emailId}
        />
        {errors.emailId && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "red",
              marginLeft: "12px",
            }}
          >
            <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
            <span style={{ marginLeft: "12px", fontSize: "14px" }}>
              {errors.emailId}
            </span>
          </div>
        )}
        <TextField
          type={showPassword ? "text" : "password"}
          size="small"
          fullWidth
          autoComplete="current-password"
          name="password"
          value={values.password}
          onChange={handleChange}
          placeholder="Password"
          sx={{
            marginTop: "15px",
          }}
          error={errors.password}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            className: "textField-input",
          }}
        />
  
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "red",
            marginLeft: "12px",
          }}
        >
          {errors.password && (
            <>
              <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
              <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                {errors.password}
              </span>
            </>
          )}
        </div>
        {/* REVIST As the admin is creating the account for the client */}
        <span
          onClick={goToForgotPassword}
          style={{ cursor: "pointer", fontSize: "14px", color: "blue" }}
        >
          Forgot Password?
        </span>
        <Button
          type="submit"
          variant="contained"
          sx={{ marginTop: "15px", width: "100%", textTransform: "capitalize" }}
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} size={24} />
          ) : (
            "log in"
          )}
        </Button>
  
        {/* REVIST As the admin is creating the account for the client */}
        <Typography
          sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
        >
          Don't have an account?{" "}
          <span
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              textTransform: "none",
              color: "blue",
              fontWeight: "600",
            }}
            onClick={redirectToSignUpPage}
          >
            Sign Up
          </span>
        </Typography>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogContent>
            <p>{dialogMessage}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={redirectToEmailVerifyPage}>
              Go to Email Verification page
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  };
  
  export default SpoTrueLoginComponent;
  