import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CustomModal from "../custom/CustomModal";
import { useAuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

const AuditCredentials = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { userDetails } = useAuthContext();
  const history = useNavigate();
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleClick = () => {
    history("/forgot-password");
  };
  return (
    <Grid>
      <CustomModal
        open={openDialog}
        isLoading={false}
        title={"Change Your Password"}
        onClose={handleDialogClose}
        primaryActionText="CHANGE YOUR PASSWORD"
        primaryActionOnclick={handleClick}
      >
        <Grid container padding={7}>
          <Grid item >
            <Typography
              variant="h5"
              display="inline"
              sx={{ fontWeight: "bold", color: "primary.main" }}
            >
              Email :
            </Typography>
          </Grid>
          <Grid item >
            <Typography
              variant="h6"
              display="inline"
              sx={{ ml: 1, color: "text.secondary" }}
            >
              {userDetails?.emailId}
            </Typography>
          </Grid>
        </Grid>
      </CustomModal>
      <Paper
        sx={{
          marginTop: "16px",
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.3)",
          backgroundColor: "background.default",
          textAlign: "left",
        }}
      >
        <Grid container item xs={12}>
          <Grid item xs={11} style={{ display: "flex" }}>
            <Typography variant="h4">Your Credentials</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              style={{
                float: "right",
                cursor: "pointer",
              }}
              variant="h4"
              align="right"
            >
              <Tooltip title="edit credentials">
                <IconButton onClick={handleDialogOpen}>
                  <ModeEditIcon />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Divider />
          <Box mt={2}>
            <Typography variant="h5" display="inline">
              Email :
            </Typography>
            <Typography variant="h6" display="inline" sx={{ ml: 1 }}>
              {userDetails?.emailId}
            </Typography>{" "}
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AuditCredentials;
