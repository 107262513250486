import { AuthApiBaseUrl } from "./baseUrls";
import { fetchWrapper } from "@gogig-in/gogig-clients";

export async function newPassword(authHeader:string, password: string, token: string) {
    const url = `${AuthApiBaseUrl}/newPassword?resetToken=${token}`;
    return fetchWrapper( url,{
        method: 'PATCH',
        body: {password},
        headers: {
            Authorization: authHeader,
          },
    });
}