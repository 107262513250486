import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiStorageBaseUrl,clientId,SpotrueClientId} from "./baseUrls";

export async function uploadFileWithMetadata(authHeader:string,formData: FormData) {
  const target = process.env.REACT_APP_TARGET;
  return fetchWrapper(
    `${SpotrueApiStorageBaseUrl}/uploadFileWithMetadata?clientId=${target==="spotrue"?SpotrueClientId:clientId}`,
    {
      method: "POST",
      headers: {
        Authorization: authHeader,
      },
      body: formData,
    }
  );
}
