import { Card, Typography, Divider, Grid, Box } from "@mui/material";
import React from "react";
import { AuditRequest } from "./vehicleAuditComponents/types";
import AuditRequestComment from "./AuditRequestComment";

interface AuditRequestDetailsCardProps {
  fileData: AuditRequest | undefined;
  fileDetails: {
    name: string;
    url: string;
  }[];
}

const AuditRequestDetailsCard: React.FC<AuditRequestDetailsCardProps> = ({
  fileData,
  fileDetails,
}) => {
  return (
    <Box sx={{display:"flex" ,width:"100%", flexDirection:{xs:"column",md:"row",sm:"row"}}}>
      <Card
        variant="outlined"
        sx={{
          padding: 3,
          borderRadius: "8px",
          boxShadow: 4,
          backgroundColor: "#f9f9f9",
          overflow: "hidden", // Prevent scrolling
          width:{sx:"100%",md:fileData?.allowRequestEdit?"40%":"100%", sm:fileData?.allowRequestEdit?"40%":"100%"}
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "#333",
          }}
        >
          Audit Request Details
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Grid
          container
          spacing={3}
          xs={6}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item sm={6}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textSecondary"
            >
              Campaign Name
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {fileData?.campaignName || "N/A"}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="textSecondary"
            >
              Vehicle Type
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {fileData?.campaignVehicleClass || "N/A"}
            </Typography>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#444" }}
          >
            Uploaded Files
          </Typography>
          <Grid container spacing={2}>
            {fileDetails.map((file, index) => (
              <Grid item key={index} xs={12} sm={4} md={3}>
                {" "}
                {/* Adjusts for mobile and desktop */}
                <Typography
                  component="a"
                  href={`https://docs.google.com/viewer?url=${encodeURIComponent(
                    file.url
                  )}&embedded=true`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: { xs: "14px", sm: "16px" }, // Adjust font size for mobile
                    color: "#007BFF",
                    wordWrap: "break-word", // Ensures long words wrap
                    whiteSpace: "normal",
                    width: { md: "180px", sm: "300px", xs: "180px" },
                    overflowWrap: "break-word",

                    "&:hover": {
                      backgroundColor: "#E6F2FF",
                      color: "#0056b3",
                    },
                  }}
                >
                  {file.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Card>
      {fileData?.allowRequestEdit ? (
  <Card
    variant="outlined"
    sx={{
      padding: 3,
      borderRadius: "8px",
      boxShadow: 4,
      backgroundColor: "#f9f9f9",
      overflow: "hidden",
      width: {
        sx: "100%",
        md: fileData?.allowRequestEdit ? "40%" : "100%",
        sm: fileData?.allowRequestEdit ? "40%" : "100%",
      },
      ml: { md: 1, sm: 1 },
      mt: { xs: 1, md: 0, sm: 0 },
    }}
  >
    <AuditRequestComment
      gogigReqId={fileData?.gogigRequestId}
      reviewComment={fileData?.reviewComment}
    />
  </Card>
) : null}
    </Box>
  );
};

export default AuditRequestDetailsCard;
