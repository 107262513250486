import { Outlet, useNavigate } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import { userRoles } from "../types/userRoles";
// import UnauthorizedPageAccess from "./UnAuthorizedAccess";

const ExternalRoutes = () => {
  const { userDetails, isAuthenticated } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (userDetails !== undefined) {
      setLoading(false);
    }
  }, [userDetails]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        px={3}
        mt={4}
      >
        <Typography variant="h6" color="textSecondary" gutterBottom>
          You need to log in to use the audit tool.
        </Typography>
        <Button
          variant="outlined"
          onClick={handleLogin}
          sx={{
            color: "primary",
            fontWeight: "bold",
            borderWidth: 2,
            paddingX: 15,
          }}
        >
          LOGIN
        </Button>
      </Box>
    );
  }

  // const hasAccess = userDetails?.role === userRoles.admin || userDetails?.role === userRoles.external;

  // if (!hasAccess) return <UnauthorizedPageAccess />;

  return <Outlet />;
};

export default ExternalRoutes;
