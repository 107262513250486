import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
} from "@mui/material";

const SpoTrueTermsAndConditions = () => {
  return (
    <Container maxWidth="lg" sx={{mt:4}}>
      <Typography variant="h3" gutterBottom align="center">
        SpoTrue Terms and Conditions
      </Typography>

      <Typography variant="body1" paragraph>
        These terms (the "Terms") form a binding legal agreement between you and
        SpoTrue ("Company" / "We" / "Us"). SpoTrue operates an online platform
        for vehicle auditing, allowing clients to submit vehicle-related data
        and receive detailed reports ("Platform").
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            1. Registration and Account
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            To use the Platform, you must create an account and agree to these
            Terms. You must ensure that the information provided during
            registration is accurate and up to date. By completing the
            registration, you are eligible to submit requests, upload relevant
            documents, and access the reports generated based on the data you
            provide. The Platform processes the information and delivers the
            report in accordance with these Terms.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            2. No Ownership of Services
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            SpoTrue does not own, control, or manage the services provided
            through the Platform. The Company only facilitates the auditing
            process by offering services for data submission and report
            generation. You agree that the fees you pay are for services
            rendered as per the specific details outlined in the supplemental
            terms.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            4. Confidentiality
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            By using the Platform, you may access proprietary and confidential
            information from the Company or its clients. You agree to treat all
            such information with strict confidentiality and not to disclose or
            use it for personal gain or for the benefit of others. This
            obligation survives even after termination of these Terms for a
            period of three (3) years.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            5. Intellectual Property
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            All intellectual property rights to the reports, data, and other
            content generated through the Platform shall remain with the
            Company. You acknowledge that any inventions, concepts, or works
            developed during the provision of services to the Platform are the
            exclusive property of SpoTrue.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            7. Duties and Compliance
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            You agree to provide accurate data for vehicle auditing, perform
            services in a professional manner, and comply with any additional
            requirements stipulated in the supplemental terms of service. You
            are also responsible for complying with all relevant laws and
            regulations, including data protection laws.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            8. Data Protection
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            You are required to comply with data protection regulations
            applicable to the information you submit to the Platform. The
            Company will take appropriate measures to protect the data and
            ensure that it is processed in accordance with the Information
            Technology Act, 2000, and relevant rules in India.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            9. Termination
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            Either party may terminate this agreement by providing at least
            fifteen (15) business days' notice. In case of a breach or violation
            of these Terms, SpoTrue may terminate the agreement immediately and
            revoke access to the Platform.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            10. Indemnification
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            You agree to indemnify and hold SpoTrue harmless from any claims or
            damages arising from your use of the Platform, including any breach
            of these Terms or non-compliance with applicable laws.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            11. Modifications
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            SpoTrue reserves the right to modify these Terms at any time. Any
            changes will be posted on the Platform and communicated to you by
            email. Your continued use of the Platform after changes are made
            constitutes acceptance of the updated Terms.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: 2 }}>
            12. Governing Law
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: 16, lineHeight: 1.7, textAlign: "justify" }}
          >
            These Terms are governed by the laws of India. Any disputes arising
            from the use of the Platform will be subject to the exclusive
            jurisdiction of the courts in Bangalore, India.
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 3, textAlign: "center" }}>
        <Typography variant="body2">Last updated: December 1, 2024</Typography>
      </Box>
    </Container>
  );
};

export default SpoTrueTermsAndConditions;
