export const formatFileName = (file: File): string => {
  // Get the file name without the extension
  const fileName = file.name.split(".").slice(0, -1).join(".");

  // Remove spaces, underscores, and special characters, and replace underscores and spaces with , and make it lowercase 
  const formattedName = fileName
    .toLowerCase()
    .replace(/\s+/g, "_")
    .replace(/-/g, "_")
    .replace(/[^a-zA-Z0-9_]/g, "");

  // Append the file extension back
  const fileExtension = file.name.split(".").pop();

  return `${formattedName}.${fileExtension}`;
};
