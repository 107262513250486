import React, { useState, useEffect } from "react";
import { Button, Box, Typography } from "@mui/material";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  createUserAuditRequest,
  FileUrls,
} from "../../clients/createUserAuditRequest";
import useFileUpload from "../../hook/FileUploadHook";
import GlobalSnackbar from "../../components/snackbarComponent/Snackbar";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetaData";
import AuditRequestForm from "./vehicleAuditComponents/AuditCreationComponents/AuditRequestComponent";
import { formatFileName } from "../../utils/formatFileName";
import { useNavigate, useParams } from "react-router-dom";
import { getAuditRequestById } from "../../clients/getAuditRequestById";
import { AuditRequest } from "./vehicleAuditComponents/types";
import { getMimeTypeExcel } from "../../components/vehicleRCValidation/Utils";
import { updateUserAuditRequest } from "../../clients/updateUserAuditRequest";

const UploadAndCreateRequest = () => {
  const { authHeader, userDetails } = useAuthContext();
  const navigate = useNavigate();
  const { gogigReqId } = useParams<{ gogigReqId: string }>();
  const [comment, setComment] = React.useState<string>("");
  const { uploadFile, loading, setLoading, error } = useFileUpload();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<boolean>(false);
  const [isFileUpload, setIsFileUpload] = useState<boolean | null>(null);
  const [isRequestTitle, setIsRequestTitle] = useState<boolean>(true);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<number, number>>(
    {}
  );
  const [vehicleClass, setVehicleClass] = useState<string>("");
  const [campaignName, setCampaignName] = useState<string>("");
  const [campaignStartDate, setCampaignStartDate] = useState<string>("");
  const [campaignEndDate, setCampaignEndDate] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [isrequestId, setIsRequestId] = useState<boolean>(false);
  const [gogigRequestId, setGogigRequestId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [requestDetails, setRequestDetails] = useState<AuditRequest>();
  // const [uploadedUrls,setUploadedUrls]=useState<(string|null)[]>()
  interface AuditRequestPayload {
    uploadedUrls: (string | null)[];
    comment: string;
    campaignName: string;
    location: string;
    campaignStartDate: string;
    campaignEndDate: string;
    vehicleClass: string;
  }
  useEffect(() => {
    if (gogigReqId) {
      setIsRequestId(true);
    }
  }, [gogigReqId]);
  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gogigReqId ) {
          const response = await getAuditRequestById(authHeader, gogigReqId);
          const data = response as AuditRequest;
          setRequestDetails(data);
          if (data.clientUrls?.trim() && data.allowRequestEdit) {
            const urls = data.clientUrls.split(",");
            const fileArray = urls.map((url, index) => {
              const fileName = url.split("/").pop() || `file-${index}`; // Fallback to a default name
              const mimeType = getMimeTypeExcel(url); // Determine MIME type
              const fileContent = `File link: ${url}`; // Placeholder content for the file

              // Create a Blob and then a File
              return new File([fileContent], fileName, { type: mimeType });
            });
            setSelectedFiles(fileArray);
            setCampaignName(data?.campaignName);
            setLocation(data?.campaignLocation);
            setCampaignStartDate(data?.campaignStartDate);
            setCampaignEndDate(data?.campaignEndDate);
            setVehicleClass(data?.campaignVehicleClass);
          }
        }
      } catch (error) {
        console.error("Error fetching audit request:", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "campaignName":
        if (value.trim()) {
          setErrorMessage(null);
        }
        setCampaignName(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "campaignStartDate":
        setCampaignStartDate(value);
        break;
      case "campaignEndDate":
        setCampaignEndDate(value);
        break;
      case "vehicleClass":
        setVehicleClass(value);
        break;
      default:
        break;
    }
  };

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const allowedTypes = [
      "application/pdf", // PDF
      "application/vnd.ms-powerpoint", // PPT
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
      "application/vnd.ms-excel", // XLS
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      "application/zip", // ZIP
      "application/x-zip-compressed", // ZIP (alternate MIME type)
    ];

    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => allowedTypes.includes(file.type));

    if (files.length !== validFiles.length) {
      setSnackbarMessage(
        "Selected file cannot be uploaded because it is unsupported file format."
      );
      setSnackbarOpen(true);
      setSeverity(true); // Assuming true indicates error severity
    }

    if (validFiles.length + selectedFiles.length > 4) {
      setSnackbarMessage("You can only upload up to 4 files.");
      setSnackbarOpen(true);
      setSeverity(true);
      return;
    }

    const formattedFiles = validFiles.map((uploadedFile) => {
      const formattedFileName = formatFileName(uploadedFile);
      console.log(formattedFileName);
      return new File([uploadedFile], formattedFileName, {
        type: uploadedFile.type,
      });
    });

    setSelectedFiles((prevFiles) => [...prevFiles, ...formattedFiles]);
  };

  const handleFileRemove = async (index: number) => {
    // if (requestDetails?.allowRequestEdit) {
    //   if (!authHeader) return;
    //   setDeletingIndex(index)
    //   try {
    //     await deleteFileFromS3(authHeader,url);
    //     setSelectedFiles((prevFiles) =>
    //       prevFiles.filter((_, i) => i !== index)
    //     );
    //     setIsDeleted(true);
    //     setSnackbarMessage("File Deleted Successfully.");
    //     setSnackbarOpen(true);
    //     setSeverity(false);
    //   } catch (error) {
    //     setSnackbarMessage("file deletion failed, try again");
    //     setSnackbarOpen(true);
    //     setSeverity(true);
    //     console.error(error);
    //   }finally{
    //     setDeletingIndex(null); // Reset deleting index after operation
    //   }
    // }else{
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    // }
  };
  const simulateUpload = (index: number) => {
    setUploadProgress((prev) => ({ ...prev, [index]: 0 }));

    const interval = setInterval(() => {
      setUploadProgress((prev) => {
        const progress = prev[index] + 10;

        if (progress >= 100) {
          clearInterval(interval);
          return { ...prev, [index]: 100 };
        }
        if (isFileUpload === true) {
          clearInterval(interval);
          return { ...prev, [index]: 100 };
        }

        return { ...prev, [index]: progress };
      });
    }, 3000);
  };

  // Define an interface for the expected response structure
interface UploadResponse {
  data: {
    fileData?: {
      fileUrl: string;
    };
  };
}

const handleUpload = async () => {
  if (!selectedFiles || !authHeader) return;
  if (!campaignName.trim()) {
    setErrorMessage("Campaign Name is required.");
    setSnackbarMessage("Campaign Name is required");
    setSnackbarOpen(true);
    setSeverity(true);
    return;
  }

  // Ensure at least one file is of required type
  const hasRequiredFile = selectedFiles.some((file) =>
    [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/zip",
      "application/octet-stream",
    ].includes(file.type)
  );

  if (!hasRequiredFile) {
    setSnackbarMessage(
      "You must upload at least one file of type PDF, PPT, or ZIP."
    );
    setSnackbarOpen(true);
    setSeverity(true); // true can represent error severity
    return;
  }

  setLoading(true);

  try {
    const urls = await Promise.all(
      selectedFiles.map(async (file, index) => {
        let url: string | null = null;
        try {
          if (
            [
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ].includes(file.type)
          ) {
            simulateUpload(index);
            const formData = new FormData();
            formData.append("excel sheet", file);

            const metadata = {
              uploaderName: `Gogig Admin ${userDetails?.firstName} ${userDetails?.lastName}`,
            };

            formData.append("tags", "AUDIT DATA");
            formData.append("metadata", JSON.stringify(metadata));

            const res = await uploadFileWithMetadata(authHeader, formData);

            // Log the response to inspect its structure
            console.log('Response from uploadFileWithMetadata:', res);

            // Type the response as UploadResponse
            const uploadRes = res as UploadResponse;

            // Check if the response contains the expected structure
            if (uploadRes?.data?.fileData?.fileUrl) {
              url = uploadRes.data.fileData.fileUrl;
            } else {
              console.error(`File upload failed for ${file.name}. Response doesn't contain fileUrl.`);
              throw new Error(`File upload failed for ${file.name}`);
            }

            setUploadProgress((prev) => ({ ...prev, [index]: 100 }));
          } else {
            simulateUpload(index);
            url = await uploadFile(file); // Call useUpload for other files
            setUploadProgress((prev) => ({ ...prev, [index]: 100 }));
            return url;
          }
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          setSnackbarMessage(`Error uploading file: ${file.name}`);
          setSnackbarOpen(true);
          setSeverity(true); // Error severity
        }
        return url;
      })
    );

    // Ensure the URLs are valid before proceeding to create the audit request
    if (urls.every((url) => url !== null)) {
      await handleCreateAuditRequest({
        uploadedUrls: urls,
        comment,
        campaignName,
        location,
        campaignStartDate,
        campaignEndDate,
        vehicleClass,
      });
    } else {
      setSnackbarMessage("Some files failed to upload.");
      setSeverity(true); // true represents error severity
      setSnackbarOpen(true);
    }
  } catch (error) {
    console.error("An unexpected error occurred:", error);
    setSnackbarMessage("An error occurred during upload. Please try again.");
    setSeverity(true); // true represents error severity
    setSnackbarOpen(true);
  } finally {
    setLoading(false); // Always stop loading, regardless of success or failure
  }
};

 
  const handleCreateAuditRequest = async ({
    uploadedUrls,
    comment,
    campaignName,
    location,
    campaignStartDate,
    campaignEndDate,
    vehicleClass,
  }: AuditRequestPayload) => {
    if (!uploadedUrls || uploadedUrls.length === 0) {
      setIsRequestTitle(false);
      return;
    }
    setLoading(true);
    let payload: FileUrls = {
      clientUrls: uploadedUrls.join(", "),
      clientComment: comment,
      campaignName: campaignName,
      location: location,
      campaignStartDate: campaignStartDate,
      campaignEndDate: campaignEndDate,
      vehicleClass: vehicleClass,
    };

    // Create audit request logic here
    try {
      if (requestDetails?.allowRequestEdit) {
        if (!authHeader || !gogigReqId) return;
        await updateUserAuditRequest(
          authHeader,
          gogigReqId,
          payload
        );
        setSnackbarOpen(true);
        setIsFileUpload(true); 
        setSnackbarMessage("Audit Request Updated Successfully");
        setSeverity(false);
     
        // const data=response.data as {

        // }
      } else {
        if (authHeader) {
          const response = await createUserAuditRequest(authHeader, payload);
          const data = (response.data as {
            auditRequestRecordData: {
              gogigRequestId: string;
              requesterId: string;
            };
          }).auditRequestRecordData;
          setGogigRequestId(data.gogigRequestId);
          window.history.pushState(
            null,
            "",
            `/upload-createRequest/${data.gogigRequestId}`
          );
          setLoading(false);

          setIsRequestId(true);
          setIsFileUpload(true); // Only set this if the audit request is successful
          setSnackbarOpen(true);
          setSnackbarMessage("Audit Request Created Successfully");
          setSeverity(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setIsRequestId(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Audit Request Creation Failed");
      setSeverity(true);
      setIsFileUpload(false);
    }
  };

  const handleClearAll = () => {
    setSelectedFiles([]);
    setErrorMessage(null);
    setIsFileUpload(false);
    setCampaignEndDate("");
    setCampaignName("");
    setCampaignStartDate("");
    setVehicleClass("");
    setLocation("");
  };
  const handleGoToRequestDetails = () => {
    // Redirect to the details page
    navigate(`/audit-process/${gogigReqId ? gogigReqId : gogigRequestId}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center align horizontally
        justifyContent: "center", // Center align vertically
        // Light background for contrast
        overflow: "auto", // Handle overflow for smaller screen
        padding: { xs: 2, sm: 3, md: 4 }, // Responsive padding
      }}
    >
      {/* Global Snackbar */}
      <GlobalSnackbar
        open={error != null || snackbarOpen}
        message={error ? error : snackbarMessage ? snackbarMessage : ""}
        onClose={() => setSnackbarOpen(!snackbarOpen)}
        severity={error || severity ? "error" : "success"}
        vertical="top"
        horizontal="center"
      />
      {isrequestId && !requestDetails?.allowRequestEdit ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: 2, sm: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: "#e3f2fd",
            marginBottom: { xs: 2, sm: 3 },
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            Your request has been created successfully
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginBottom: 3 }}
          >
            Click the button below to view the details of your request.
          </Typography>

          <Button
            variant="contained"
            sx={{
              padding: { xs: "10px 20px", sm: "12px 24px" },
              backgroundColor: "#1976d2",
              color: "#fff",
              "&:hover": { backgroundColor: "#1565c0" },
            }}
            onClick={handleGoToRequestDetails}
          >
            View Request Details
          </Button>
        </Box>
      ) : (
        <AuditRequestForm
        // uploadedUrls={uploadedUrls}
          requestDetails={requestDetails}
          location={location}
          campaignEndDate={campaignEndDate}
          campaignStartDate={campaignStartDate}
          campaignName={campaignName}
          vehicleClass={vehicleClass}
          errorMessage={errorMessage}
          handleInputChange={handleInputChange}
          isRequestTitle={isRequestTitle}
          handleUpload={handleUpload}
          onRemoveFile={handleFileRemove}
          loading={loading}
          setComment={setComment}
          comment={comment}
          uploadProgress={uploadProgress}
          selectedFiles={selectedFiles}
          handleFileSelection={handleFileSelection}
          isFileUpload={isFileUpload}
          // isDeleted={isDeleted}
          // deletingIndex={deletingIndex}
        />
      )}

      {/* Clear All Button Box */}
      {(selectedFiles.length > 0 ||
        campaignName ||
        campaignEndDate ||
        campaignStartDate ||
        location ||
        vehicleClass) &&
        !isrequestId && (
          <Box
            sx={{
              maxWidth: "800px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              paddingX: { xs: 2, sm: 3 }, // Adjust horizontal padding for smaller screens
              boxSizing: "border-box",
              marginTop: { xs: 2, sm: 3 }, // Add some top margin on smaller screens
            }}
          >
            <Button
              sx={{
                padding: { xs: "8px 12px", sm: "10px 16px" }, // Adjust button padding for smaller screens
                backgroundColor: "#1976d2",
                color: "#fff",
                "&:hover": { backgroundColor: "#1565c0" },
                ml: { md: 4 },
              }}
              variant="contained"
              onClick={handleClearAll}
            >
              Clear All
            </Button>
          </Box>
        )}
    </Box>
  );
};

export default UploadAndCreateRequest;
