import { fetchWrapper } from "@gogig-in/gogig-clients";
import {  clientId,SpotrueClientId, SpotrueApiStorageBaseUrl } from "./baseUrls";

export interface UploadedPart {
  ETag: string | null;
  PartNumber: number;
}

export default function completeFileUpload(
  fileId: string,
  uploadId: string,
  parts: UploadedPart[]
) {
  const target = process.env.REACT_APP_TARGET;
  return fetchWrapper(`${SpotrueApiStorageBaseUrl}/completeFileUpload?clientId=${target==="spotrue"?SpotrueClientId:clientId}`, {
    method: "POST",
    body: {
      fileId: fileId,
      uploadId: uploadId,
      parts: parts,
    },
  });
}
