import React from "react";
import {Typography, Button, TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface AuditRequestCommentProps {
  gogigReqId?: string;
  reviewComment?: string;
}

const AuditRequestComment: React.FC<AuditRequestCommentProps> = ({ gogigReqId, reviewComment }) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/upload-createRequest/${gogigReqId}`); // Navigate to the edit page
  };

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>
        Audit Request Review
      </Typography>
      <Box sx={{ mb: 2 }}>
        {/* Use a TextField for viewing (disabled) */}
        <TextField
          label="Comment"
          value={reviewComment}
          fullWidth
          variant="outlined"
          disabled // Make the text field read-only
          InputProps={{
            readOnly: true, // Ensures it can't be edited
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end"  }}>
        <Button variant="contained" color="primary" onClick={handleEditClick}>
          Edit Your Campaign
        </Button>
      </Box>
    </>
  );
};

export default AuditRequestComment;
