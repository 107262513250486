import { Container, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";

const SpoTruePrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Grid item xs={12}>
        <Typography variant="h1" my={3}>
          Privacy Policy
        </Typography>
      </Grid>

      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={700}>
          What information do we collect?
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            We collect information from you when you register on the site.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            When registering, we may ask you for your name, e-mail address,
            mailing address, phone number, or other information. You may,
            however, visit our site anonymously
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            Like many websites, we use "cookies" to enhance your experience and
            gather information about visitors and visits to our websites. Please
            refer to the "Do we use "cookies"?" section below for information
            about cookies and how we use them.
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h4" fontWeight={700} id="what-info-used">
          What Information is Used?
        </Typography>
        <br />
        <Typography
          variant="body2"
          style={{
            textAlign: "justify",
          }}
        >
          We may use the information we collect from you when you register,
          respond to a questionnaire or marketing communication, surf the
          website, or use certain other site features in the following ways
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            To personalize your site experience and to allow us to deliver the
            type of work in which you are most interested.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            Any personal data collected will be used by SpoTrue to contact you via
            phone, SMS or email for marketing and sending reminder/updates
            messages.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            To allow us to better service you in responding to your requests.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            To quickly process transactions.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            We may send you periodic e-mails.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            By submitting our form, you agree to receive promotional calls on
            the number shared, and such calls and SMS would be coming from a
            third party platform.
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h4">
          {" "}
          How do we protect visitor information?
        </Typography>
        <List>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            We implement a variety of security measures to maintain the safety
            of your personal information. Your personal information is contained
            behind secured networks and is only accessible by a limited number
            of persons who have special access rights to such systems, and are
            required to keep the information confidential.
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h4">Do we use "cookies"?</Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            Yes. Cookies are small files that a site or its service provider
            transfers to your computer's hard drive through your Web browser (if
            you allow) that enables the site's or service provider's systems to
            recognize our browser and capture and remember certain information.
            For instance, we use cookies to help us understand your preferences
            based on previous or current site activity, which enables us to
            provide you with improved services. We also use cookies to help us
            compile aggregate data about site traffic and site interaction so
            that we can offer better site experiences and tools in the future.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            We may contract with third-party service providers to assist us in
            better understanding our site visitors. These service providers are
            not permitted to use the information collected on our behalf except
            to help us conduct and improve our business.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. If you turn
            cookies off, you won't have access to many features that make your
            site experience more efficient and some of our services will not
            function properly.
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h4">
          Do we disclose the information we collect to outside parties?
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information unless we provide you with
            advance notice, except as described below. The term "outside
            parties" does not include organizations who have subscribed to our
            services. It also does not include website hosting partners and
            other parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect ours or other's rights,
            property, or safety.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            However, non-personally identifiable visitor information may be
            provided to other parties for marketing, advertising, or other uses.
          </ListItem>
        </List>
      </Grid>

      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={700}>
          How can you opt-out, remove or modify information you have provided to
          us?
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            You can access or update the information you have provided to us by
            visiting your account/profile page.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            To remove yourself from our website, please send us an email or call
            us to remove your profile from our database. Please note that we may
            maintain information about you for record keeping.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            Like many websites, we use "cookies" to enhance your experience and
            gather information about visitors and visits to our websites. Please
            refer to the "Do we use "cookies"?" section below for information
            about cookies and how we use them.
          </ListItem>
        </List>
      </Grid>

      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={700}>
          Third Party Links
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            style={{
              textAlign: "justify",
            }}
          >
            In an attempt to provide you with increased value, we may include
            third party links on our site. These linked sites have separate and
            independent privacy policies. We therefore have no responsibility or
            liability for the content and activities of these linked sites.
            Nonetheless, we seek to protect the integrity of our site and
            welcome any feedback about these linked sites (including if a
            specific link does not work).
          </ListItem>
        </List>
      </Grid>

      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={700}>
          Changes to our policy
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            style={{
              textAlign: "justify",
            }}
          >
            If SpoTrue decides to change our privacy policy, we will post those
            changes on this page. Policy changes will apply only to information
            collected after the date of the change..
          </ListItem>
        </List>
      </Grid>

      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={700}>
          Questions and feedback
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            style={{
              textAlign: "justify",
            }}
          >
            We welcome your questions, comments, and concerns about privacy.
            Please send us any and all feedback pertaining to privacy, or any
            other issue.
          </ListItem>
        </List>
      </Grid>

      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={700}>
          Online Policy Only
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            style={{
              textAlign: "justify",
            }}
          >
            This online privacy policy applies only to information collected
            through our website and not to information collected offline.
          </ListItem>
        </List>
      </Grid>

      <Grid item sm={6}>
        <Typography variant="h4" fontWeight={700}>
          Terms and Conditions
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            style={{
              textAlign: "justify",
            }}
          >
            Please also visit our Terms and Conditions section establishing the
            use, disclaimers, and limitations of liability governing the use of
            our website.
          </ListItem>
        </List>
      </Grid>

      <Grid item sm={6} alignItems={"start"}>
        <Typography variant="h4" fontWeight={700}>
          Your consent
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            style={{
              textAlign: "justify",
            }}
          >
            By using our site, you are acknowledging and consenting to our
            privacy policy, which outlines how we collect, use, disclose, and
            manage your personal information. We are committed to safeguarding
            your privacy and ensuring that your information is protected.
          </ListItem>
        </List>
      </Grid>
    </Container>
  );
};

export default SpoTruePrivacyPolicy;
