import { useState } from "react";
import createFileUpload, {Body} from "../clients/createFileUpload";
import generatePresignedUrls from "../clients/generatePresignedUrls";
import completeFileUpload, {UploadedPart} from "../clients/completeFileUpload";
import uploadByPart from "../clients/uploadByParts";
import { useAuthContext } from "../providers/AuthProvider";

// Set part size to 5 MB
const PART_SIZE = 5 * 1024 * 1024;

type UploadFileHook = {
  uploadFile: (file: File) => Promise<string | null>;
  loading: boolean;
  error: string | null;
  setLoading:React.Dispatch<React.SetStateAction<boolean>>;
};

const useFileUpload = (): UploadFileHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const {authHeader} =useAuthContext()
  const uploadFile = async (file: File): Promise<string | null> => {
    const fileName = file.name;
    const fileType = file.type;
    console.log("File Type:", fileType);

    // Validate the file type (Allow .pptx, .zip, and .pdf files)
    const allowedFileTypes = [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
      "application/x-zip-compressed",
      "application/zip", // .zip
      "application/pdf", // .pdf
    ];

    if (!allowedFileTypes.includes(fileType)) {
      setError("Invalid file type. Please upload a .pptx, .zip, or .pdf file.");
      return null;
    } 
    try {
      if(!authHeader) return null;
      setLoading(true);
      const fileSize = file.size;
      const totalParts = Math.ceil(fileSize / PART_SIZE);

      // Step 1: Initiate file upload
      const payload: Body = {
        fileName: fileName,
        fileType: "multipart/form-data",
      };
      const response = await createFileUpload(authHeader, payload);
      const uploadId = (response.data as { UploadId: string }).UploadId;
      const fileId= (response.data as {fileId:string}).fileId
      // Step 2: Generate pre-signed URLs for each file part
      const presignedUrls = await generatePresignedUrls(
        fileId,
        uploadId,
        `${totalParts}`
      );
      const urls = (
        presignedUrls.data as {
          presignedUrls: { partNumber: number; url: string }[];
        }
      ).presignedUrls;

      // Step 3: Upload file parts
      const uploadedParts: UploadedPart[] = [];
      for (let i = 0; i < totalParts; i++) {
        const start = i * PART_SIZE;
        const end = Math.min(start + PART_SIZE, file.size);
        const chunk = file.slice(start, end);

        const etag = await uploadFilePart(urls[i].url, chunk);
        uploadedParts.push({
          ETag: etag,
          PartNumber: i + 1,
        });
      }

      // Step 4: Complete the file upload
      const fileResponse = await completeFileUpload(
        fileId,
        uploadId,
        uploadedParts
      );
      const location = (fileResponse.data as { location: string }).location;

      return location;
    } catch (uploadError) {
      setError("Error during file upload.");
      console.error(uploadError);
      setLoading(false);
      return null;
    }
  };

  // Function to upload individual file parts
  const uploadFilePart = async (
    url: string,
    partBuffer: Blob
  ): Promise<string> => {
    const response = await uploadByPart(url, partBuffer);
    return response.headers.get("etag") || "";
  };
  // // Function to handle post-upload processing and return the processed URL
  // const processUploadedFile = async (url: string): Promise<string | null> => {
  //   if (!authHeader) {
  //     setError("Missing authentication header.");
  //     return null;
  //   }

  //   try {
  //     const response = await getVehiclePPTForPreProcessing(authHeader, url);
  //     const processedUrl = (response.data as { url: string }).url;
  //     return processedUrl;
  //   } catch (processError) {
  //     setError("Error during file processing.");
  //     console.error(processError);
  //     return null;
  //   }
  // };

  return { uploadFile, loading, setLoading, error };
};

export default useFileUpload;
