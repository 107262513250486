import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import dateFormat from "dateformat";

// Define prop types for GuideLines
interface GuideLinesProps {
  status: string;
  allowRequestEdit?: boolean;
  dueDate?: string;
}

export const CurrentAndNextStepInfo: React.FC<GuideLinesProps> = ({
  status,
  allowRequestEdit,
  dueDate,
}) => {
  const getGuidelineText = (stage: string) => {
    switch (stage) {
      case "requestCreated":
        return {
          title: "Request Created",
          description:
            "Your request has been successfully created! We will carefully review your files to ensure the best possible results.",
          nextStep:
            "The review process for your audit request will start soon. Once it begins, you will receive an update via email or you can visit this website for more details.",
        };

      case "requestUnderReview":
        return {
          title: "Request Under Review",
          description:
            "The review process for your audit request has now begun! Our team is thoroughly reviewing the data.",
          nextStep:
            "Once the review is complete, you will be updated on the next steps. If everything is good, we will proceed with the audit process.",
        };

      case "requestApproved":
        return {
          title: "Request Approved",
          description: `Your request has been approved, and we will now begin the auditing process. You will be able to download the report on ${dateFormat(
            dueDate
          )}. If there are any changes to this date, we will notify you via email, or you can visit this page for updates `,
          nextStep:
            "The auditing process will start soon. Once it completes, we’ll review the results and proceed with post-processing.",
        };

      case "rejected":
        return {
          title: "Request Rejected",
          description: allowRequestEdit
            ? "Your request has been commented on. You can view the comments on the same page. Please make the necessary changes and re-upload your files directly from this page."
            : "Your request has been rejected due to incorrect files. Don't worry—you can create a new request with the correct files.",
          nextStep: allowRequestEdit
            ? "Please review the comments, make the necessary corrections, and re-upload the files. Once updated, your request will go under review again."
            : "To move forward, you can create a new request with the correct files and submit them for processing.",
        };

      case "processing":
        return {
          title: "Processing",
          description:
            `The auditing process for your request has now begun.  You will be able to download the report on ${dateFormat(
            dueDate
          )}. If there are any changes to this date, we will notify you via email, or you can visit this page for updates `,
          nextStep:
            "Once processing is complete, we will review the results of your audit request, after that you can download your audit report.",
        };
        case "resultsUnderReview":
          return{
            title: "Results Under Review",
            description:`Your audit request results are being reviewed.  You will be able to download the report on ${dateFormat(
            dueDate
          )}. If there are any changes to this date, we will notify you via email, or you can visit this page for updates`,
          nextStep:
           "once your audit request results review is completed,we will notify you via email, or you can visit this page for updates "
          };
          case "completed":
            return{
              title:" Completed",
              description:"you can download the report now, from dashboard, thank you!",
              nextStep:"go to dashboard, ther you can see the download button , from there you can download your report"
            }
    }
  };
  const getBulletPoints = (text: string) => {
    return text.split("\n").map((point, index) => {
      return point.trim().endsWith(".")
        ? point.trim().slice(0, -1)
        : point.trim();
    });
  };

  const guideline = getGuidelineText(status);

  return (
    <Card variant="outlined" sx={{  boxShadow: 3, width: "100%",}}>
    <CardContent>
      <Stack spacing={2}>
        <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
          <Info sx={{ color: "#212121", marginRight: 1 }} />
          <Typography variant="h6" fontWeight="bold">
            {guideline?.title}
          </Typography>
        </Box>
  
        <Divider sx={{ my: 2 }} />
  
        <Typography variant="body1" fontWeight="bold">
          Current Step:
        </Typography>
        <List dense>
          {guideline &&
            getBulletPoints(guideline.description).map((point, index) => (
              <ListItem
                key={index}
                sx={{ display: "list-item", paddingLeft: 2, wordBreak: "break-word" }}
              >
                <Typography variant="body2">{point}</Typography>
              </ListItem>
            ))}
        </List>
  
        <Divider sx={{ my: 2 }} />
  
        <Typography variant="body1" fontWeight="bold">
          Next Steps:
        </Typography>
        <List dense>
          {guideline &&
            getBulletPoints(guideline.nextStep).map((point, index) => (
              <ListItem
                key={index}
                sx={{ display: "list-item", paddingLeft: 2, wordBreak: "break-word" }}
              >
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: point }}
                />
              </ListItem>
            ))}
        </List>
      </Stack>
    </CardContent>
  </Card>
  
  );
};
